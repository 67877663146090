<template>
    <div class="h-full d-flex align-center justify-center flex-column">
        <h3>You need to be logged in.</h3>

        <v-btn
            color="mainRed"
            class="mt-2"
            dark
            @click="openAccountsApp"
        >
            log in
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'Login',

    created() {
        localStorage.getItem('authenticated') === 'true' && this.$router.push('/');
    },

    methods: {
        openAccountsApp() {
            this.$store.dispatch('openAccountsApp', { path: 'login' });
        },
    },
};
</script>

<style scoped></style>